import 'react-toastify/dist/ReactToastify.css';

import {
    AssetUploadResponse,
    InterludesUploadResponse,
    PollUploadResponse,
    renderAssetResults,
    renderInterludeResults,
    renderPollResults,
    renderUploadErrorsFromResponse,
} from './UploadResults';
import { Flashbar, FormSection, Modal, TokenGroup } from '@amzn/awsui-components-react';
import { FlashbarItem, itemError, itemSuccess } from './commons/flash-messages';
import PageHeader, { PageHeaderButton } from './PageHeader';
import React, { ChangeEvent, useState } from 'react';
import { fetchUploadAssets, fetchUploadInterludes, fetchUploadPolls } from '../utils/fetchUtil';

import { BulkUploadError } from '../utils/bulkUploadError';
import PageHelp from './help/PageHelp';
import { StageContext } from './StageContext';
import { bulkUploadHelp } from './help/HelpContent';
import { useContext } from 'react';

interface State {
    flashbar: FlashbarItem[];
    filesSelected: boolean;
    uploading: boolean;
    showResults: boolean;
    showUploadErrors: boolean;
    files: File[];
}

interface Props {
    title: string;
    results: React.ReactNode;
    uploadErrors: React.ReactNode;
    doUpload: (data: FormData) => Promise<void>;
}

class BulkUpload extends React.Component<Props, State> {
    state: State = {
        flashbar: [],
        filesSelected: false,
        uploading: false,
        showResults: false,
        showUploadErrors: false,
        files: [],
    };

    uploadButtonRef = React.createRef<HTMLInputElement>();

    // On file select (from the pop up)
    onFileChange = (event: ChangeEvent<HTMLInputElement>): void => {
        // Update the state
        if (event.target.files) {
            const files = this.state.files;
            for (let i = 0; i < event.target.files.length; i++) {
                files.push(event.target.files.item(i) as File);
            }
            this.setState({ filesSelected: files.length > 0, files: files });
        }
    };

    clearFiles(): void {
        this.setState({ filesSelected: false, files: [] });
    }

    // On file upload (click the upload button)
    onFileUpload = (): Promise<void> => {
        // Create an object of formData
        const formData = new FormData();
        this.state.files.forEach((file) => formData.append('file', file));

        // Request made to the backend api
        this.setState({ uploading: true });
        return this.props
            .doUpload(formData)
            .then(() => {
                this.clearFiles();
                this.setState({
                    flashbar: [
                        itemSuccess('File(s) uploaded successfully').withButton('View results', () =>
                            this.setState({ showResults: true }),
                        ),
                    ],
                });
            })
            .catch((error) => {
                if (error instanceof BulkUploadError) {
                    this.setState({
                        flashbar: [
                            itemError('Batch upload failed', error).withButton('View details', () =>
                                this.setState({ showUploadErrors: true }),
                            ),
                        ],
                    });
                } else {
                    this.setState({
                        flashbar: [itemError('Batch upload failed', error)],
                    });
                }
            })
            .finally(() => this.setState({ uploading: false }));
    };

    onAddFile = (): void => {
        this.uploadButtonRef.current?.click();
    };

    onRemoveFile = (e: CustomEvent<TokenGroup.DismissDetail>): void => {
        const files = this.state.files.concat([]);
        files.splice(e.detail.itemIndex, 1);
        this.setState({ files: files, filesSelected: files.length > 0 });
    };

    humanFileSize(bytes: number): string {
        const metric = false;
        const thresh = metric ? 1000 : 1024;
        const dp = 1; // Decimal places

        if (Math.abs(bytes) < thresh) {
            return bytes + ' B';
        }

        const units = metric
            ? ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
            : ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        let u = -1;
        const r = 10 ** dp;

        do {
            bytes /= thresh;
            ++u;
        } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

        return bytes.toFixed(dp) + ' ' + units[u];
    }

    render(): JSX.Element {
        const headerButtons: PageHeaderButton[] = [
            {
                text: 'Add file',
                icon: 'add-plus',
                disabled: this.state.uploading,
                onClick: this.onAddFile,
            },
            {
                text: 'Upload',
                icon: 'upload',
                variant: 'primary',
                disabled: !this.state.filesSelected,
                loading: this.state.uploading,
                onClick: this.onFileUpload,
            },
        ];
        const fileItems = this.state.files.map((file) => ({
            label: file.name,
            description: file.type,
            tags: [this.humanFileSize(file.size)],
            iconName: 'file',
        }));
        const uploadFooter = this.state.filesSelected
            ? `${this.state.files.length} files selected`
            : 'No files have been selected';
        return (
            <div>
                <Modal
                    visible={this.state.showResults}
                    header="Batch upload results"
                    onDismiss={() => this.setState({ showResults: false })}
                >
                    {this.props.results}
                </Modal>
                <Flashbar items={this.state.flashbar} />
                <Modal
                    visible={this.state.showUploadErrors}
                    header="Batch upload errors"
                    onDismiss={() => this.setState({ showUploadErrors: false })}
                >
                    {this.props.uploadErrors}
                </Modal>
                <PageHelp content={bulkUploadHelp()} openPanel={true} />
                <PageHeader text={this.props.title} buttons={headerButtons} />
                <FormSection header="Upload CSV files" footer={uploadFooter}>
                    {this.state.filesSelected && <TokenGroup items={fileItems} onDismiss={this.onRemoveFile} />}
                </FormSection>
                <input
                    className="awsui-util-hide"
                    type="file"
                    formEncType="multipart/form-data"
                    accept=".csv"
                    multiple
                    onChange={this.onFileChange}
                    ref={this.uploadButtonRef}
                />
            </div>
        );
    }
}

export const BulkUploadInterludes: React.FC = () => {
    const [results, setResults] = useState<InterludesUploadResponse>();
    const { dataStage } = useContext(StageContext);
    const doUpload = async (data: any) => {
        try {
            setResults(await fetchUploadInterludes(data, dataStage));
        } catch (error) {
            setResults(error);
            throw error;
        }
    };
    return (
        <BulkUpload
            title="Bulk upload interludes"
            results={renderInterludeResults(results)}
            uploadErrors={renderUploadErrorsFromResponse(results)}
            doUpload={doUpload}
        />
    );
};

export const BulkUploadAssets: React.FC = () => {
    const [results, setResults] = useState<AssetUploadResponse>();
    const { dataStage } = useContext(StageContext);
    const doUpload = async (data: any) => {
        try {
            setResults(await fetchUploadAssets(data, dataStage));
        } catch (error) {
            setResults(error);
            throw error;
        }
    };
    return (
        <BulkUpload
            title="Bulk upload assets"
            results={renderAssetResults(results)}
            uploadErrors={renderUploadErrorsFromResponse(results)}
            doUpload={doUpload}
        />
    );
};

export const BulkUploadPolls: React.FC = () => {
    const [results, setResults] = useState<PollUploadResponse>();
    const doUpload = async (data: any) => {
        try {
            setResults(await fetchUploadPolls(data));
        } catch (error) {
            setResults(error);
            throw error;
        }
    };
    return (
        <BulkUpload
            title="Bulk upload polls"
            results={renderPollResults(results)}
            uploadErrors={renderUploadErrorsFromResponse(results)}
            doUpload={doUpload}
        />
    );
};
